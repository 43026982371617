const PackageFeatures = {
  DEVICES: 'devices',
  MEDIA: 'media',
  PROFILE: 'profile',
  TARGET_ADS: 'target-ads',
  SITE: 'site',
  INTERNAL_NETWORK_ADS: 'banner-campaigns',
  REPORT: 'report',
}

export default PackageFeatures
