/* eslint-disable no-unused-vars */
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    allDevices: [],
  },
  mutations: {
    SET_DEVICES(state, payload) {
      state.allDevices = [...payload]
    },
  },
  actions: {
    async getDevices({ commit }, {
      groupId,
    }) {
      try {
        const {
          data: { data },
        } = await Vue.prototype.$axios.get('user/device', {
          headers: {
            userHasGroupId: groupId,
          },
        })
        commit('SET_DEVICES', data.results)
      } catch (e) {
        console.log(e)
        throw new Error('No devices found')
      }
    },
    async deleteDevice({ commit }, { macAddress, userHasGroupId }) {
      try {
        const response = await Vue.prototype.$axios.delete(
          `/user/device/remove/${macAddress}`, {
            headers: {
              userHasGroupId,
            },
          },
        )
        return true
      } catch (e) {
        console.error(e)
        throw new Error(e)
      }
    },
  },
  getters: {
    getDevices: state => state.allDevices,
  },
}
